import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import bankList from "@/config/bank.json"
import citiesList from "@/config/citiesList.json"
import { paymentTypes } from "modules/base/config/paymentConstants"

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {

    }
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'module.provider-info',
      defaultData: this._defaultData,
      dataAction: {
        status: false,
        back: false,
        delete: false,
        refresh: false,
      },
      blocks: [
        {
          title: 'form_block.base',
          disabled: (formInfo) => {
            //@ts-ignore
            return formInfo.formData.member_id != vm.memberId
          },
          help: (formInfo) => {
            //@ts-ignore
            return formInfo.formData.member_id != vm.memberId ? vm.$t('only.main-manager.edit') : ''
          },
          data: {
            name: {
              required: true,
              label: 'provider.data.name',
              type: 'liff-text',
              disabled: true,
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            member_name: {
              label: 'provider.data.member_name',
              type: 'liff-text',
              disabled: true,
              required: true,
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            member_phone: {
              label: 'data.member_phone',
              type: 'liff-text',
              disabled: true,
              required: true,
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            phone: {
              label: 'provider.data.phone',
              type: 'liff-text',
              required: true,
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            email: {
              label: 'data.email',
              type: 'liff-text',
              required: true,
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            uniform_number: {
              label: 'data.uniform_number',
              type: 'liff-text',
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            city: {
              label: 'provider.data.city',
              type: 'liff-selection',
              clearable: true,
              options: () => {
                return citiesList.map(item => ({
                  text: item.city,
                  value: item.city
                }))
              },
              grid: {
                cols: 6,
              },
              itemClass: "mb-2",
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            district: {
              label: 'provider.data.district',
              type: 'liff-selection',
              clearable: true,
              options: (formInfo) => {
                if (!formInfo.formData) return []
                const city = formInfo.formData.city
                if (!city) return []

                const targetCity = citiesList.find(item => {
                  return item.city == city
                })

                if (!targetCity) return []

                return targetCity.districts.map(district => ({
                  text: district.name,
                  value: district.name
                }))
              },
              grid: {
                cols: 6,
              },
              itemClass: "mb-2",
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            address: {
              label: 'data.ship-address',
              type: 'liff-text',
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            payment_type: {
              label: 'data.payment_type',
              required: true,
              type: 'liff-selection',
              multiple: true,
              clearable: true,
              options: (formInfo) => {
                return paymentTypes.map(type => ({ text: vm.$t(`data.payment_type.${type}`), value: type }))
              },
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return window.eagleLodash.isEqual(originalData, data) === false
              },
            },
            bank_account_name: {
              label: 'provider.data.bank_account_name',
              type: 'liff-text',
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            bank_account: {
              label: 'provider.data.bank_account',
              type: 'liff-text',
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            bank_info: {
              label: 'provider.data.bank_info',
              type: 'liff-selection',
              clearable: true,
              options: (formInfo) => bankList.map(bank => ({
                text: `(${bank.id})${bank.name}`,
                value: `(${bank.id})${bank.name}`,
              })),
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },
            bank_branch_id: {
              label: 'provider.data.bank_branch_id',
              type: 'liff-text',
              hasChanged: ({ originalData, data, bothEmpty }: FormDataChangeHelperInterface) => {
                if (bothEmpty(originalData, data)) return false
                return originalData != data
              },
            },

          },
        },
      ],
    }
  }

}

export default new formConfig()
