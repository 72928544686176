<template>
  <v-container>

    <eagle-form :form-key="formKey">
    </eagle-form>

    <div v-if="false" class="mb-4">{{ shareConfig }}</div>
  </v-container>
</template>

<script lang="babel" type="text/babel">
import formMixin from '@/components/form/mixins/formMixin'
import formConfig from './providerInfo'

export default {
  mixins: [formMixin],
  data: () => ({
    formKey: 'provider-info-form',
    meta: {},
  }),
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
  },
  methods: {
    async beforeFormInit() {
      await Promise.all([])
    },
    validateBeforeSave() {
      // TODO
    },
    afterSaveSuccessCallback(result) {

    },
    async readApi(target) {
      const res =  await this.$api.collection.providerApi.read(this.providerId)
      this.$store.dispatch('base/setLayoutProvider', res)
      return res
    },
    async updateApi(target, formData) {
      const res = await this.$api.collection.providerApi.update(this.providerId, formData)
      // this.$store.dispatch('base/setLayoutProvider', res)
    },
    getFormConfig() {
      return formConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
